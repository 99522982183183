import React, { useState } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";

import Transition from "../components/Transition";
import { Grid } from "../ui/GridWrapper";
import { Caption, Heading3 } from "../ui/Typography";
import { SEO } from "../components/SEO";

export const Wrapper = styled(motion.div)`
  grid-column: 1 / -1;
  width: 100%;
  padding: ${(props) => props.padding};
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: calc(50% - 0.5px);
    top: 0;
    width: 1px;
    height: 100%;
    background: ${(props) => props.theme.colors.color};
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    &:before {
      display: none;
    }
  }
`;

const MediaWrapper = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 12px;
  width: 100%;
  height: auto;
  border-radius: 0%;
  transition: border-radius 0.4s ease-in-out;
  overflow: hidden;
  /* Image */
  .gatsby-image-wrapper {
    width: 100%;
    height: 415px;
  }
  /* Video */
  & > div {
    height: 415px !important;
  }

  & > div > div > div {
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }

  iframe {
    width: 177.77777778vh !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
    position: relative !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    pointer-events: none !important;
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    height: 246px;
    & > div {
      height: 100% !important;
    }
    .gatsby-image-wrapper {
      height: 246px;
    }
  }
`;

const ProjectWrapper = styled(motion.div)`
  grid-column: span 6;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
  padding-bottom: 15px;
  margin-bottom: 5px;
  max-width: 100%;

  &:hover {
    ${MediaWrapper} {
      border-radius: 100%;
    }
  }

  @media ${(props) => props.theme.breakpoints.desktop} {
    /* Select only last row elements */
    &:nth-child(2n + 1):nth-last-child(-n + 2),
    &:nth-child(2n + 1):nth-last-child(-n + 2) ~ div {
      margin-bottom: 0px;
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  @media ${(props) => props.theme.breakpoints.mobile} {
    &:nth-last-child(1) {
      margin-bottom: 0px;
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }
`;

const InfoWrapper = styled.div`
  grid-column: 1 / -1;
`;

const FilterWrapper = styled.div`
  grid-column: 1 / -1;
  border-bottom: 1px solid ${(props) => props.theme.colors.color};
  padding: 10px 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media ${(props) => props.theme.breakpoints.mobile} {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const FilterButton = styled.button`
  padding: 4px 10px;
  border-radius: 1em;
  border: 1px solid ${(props) => props.theme.colors.color};
  background: ${(props) =>
    props.active ? props.theme.colors.color : props.theme.colors.bg};
  color: ${(props) =>
    props.active ? props.theme.colors.bg : props.theme.colors.color};
  font-family: ${(props) => props.theme.fonts.mono};
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.25s ease, background 0.25s ease;
  cursor: pointer;
  white-space: nowrap;
  @media (hover: hover) {
    &:hover {
      background: ${(props) => props.theme.colors.color};
      color: ${(props) => props.theme.colors.bg};
    }
  }
`;

const ProjectPreview = ({ data }) => {
  return (
    <ProjectWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      layout
    >
      <Link to={`/work/${data.slug.current}`}>
        <MediaWrapper>
          {data.mainMedia[0]._type === "img" ? (
            <GatsbyImage
              image={data.mainMedia[0].asset.gatsbyImageData}
              alt={data.mainMedia[0].alt}
              layout="fullWidth"
              draggable={false}
            />
          ) : (
            <ReactPlayer
              url={data.mainMedia[0].vimeoURL}
              playing={data.mainMedia[0].autoplay}
              loop={data.mainMedia[0].loop}
              muted={data.mainMedia[0].muted}
              controls={data.mainMedia[0].controls}
              playsinline={data.mainMedia[0].autoplay}
              pip={false}
              width={"100%"}
              height={"auto"}
              config={{
                vimeo: {
                  playerOptions: {
                    byline: false,
                    portrait: false,
                    title: false,
                    responsive: true,
                    color: "EB3329",
                  },
                },
              }}
            />
          )}
        </MediaWrapper>
        <InfoWrapper>
          <Caption>{data.client}</Caption>
          <Heading3>{data.homeTitle}</Heading3>
        </InfoWrapper>
      </Link>
    </ProjectWrapper>
  );
};

function Work() {
  const data = useStaticQuery(graphql`
    query Work {
      allSanityProject {
        edges {
          node {
            id
            slug {
              current
            }
            client
            projectCategory {
              title
            }
            homeTitle
            mainMedia {
              ... on SanityImg {
                _key
                _type
                alt
                caption
                asset {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
              ... on SanityVideo {
                _key
                _type
                alt
                autoplay
                caption
                controls
                loop
                muted
                vimeoURL
              }
            }
          }
        }
      }
      allSanityCategory {
        edges {
          node {
            id
            title
          }
        }
      }
    }
  `);

  const [projects, setProjects] = useState(data.allSanityProject.edges);
  const [active, setActive] = useState("all");
  const categories = data.allSanityCategory.edges;

  const handButtonClick = (category) => {
    if (active === category) {
      setActive("all");
      setProjects(data.allSanityProject.edges);
      return;
    }
    setActive(category);
    handleFilterClick(category);
  };

  const handleFilterClick = (category) => {
    if (category === active) return;

    if (category === "all") {
      setProjects(data.allSanityProject.edges);
      return;
    }

    const filteredData = data.allSanityProject.edges.filter((project) =>
      project.node.projectCategory.some(({ title }) => title === category)
    );

    setTimeout(() => {
      setProjects(filteredData);
    }, 400);
  };

  return (
    <Transition>
      <FilterWrapper>
        {categories.map((item, i) => {
          return (
            <FilterButton
              active={active === item.node.title}
              key={item.node.id}
              onClick={() => handButtonClick(item.node.title)}
            >
              {item.node.title}
            </FilterButton>
          );
        })}
      </FilterWrapper>
      <Wrapper grid="1 / -1">
        <Grid>
          <AnimatePresence mode="popLayout">
            {projects.map((item, i) => {
              return (
                <ProjectPreview data={item.node} key={item.node.id} index={i} />
              );
            })}
          </AnimatePresence>
        </Grid>
      </Wrapper>
    </Transition>
  );
}

export default Work;

export const Head = () => <SEO title="Work | Pal" />;
